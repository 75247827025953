import { createStore } from 'vuex'

export default createStore({
  state: {
    account:'',
    userName:'',
    oldPwd:'',
    complaintNum:0,
    repairNum:0
  },
  mutations: {
    submit(state,{account,userName,oldPwd}){
      state.account = account
      state.userName = userName
      state.oldPwd = oldPwd
    },
    complaint(state,payload){
      state.complaintNum = payload
    },
    repair(state,payload){
      state.repairNum = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
