<template>
  <router-view/>
</template>

<style scoped>
  body,html{
    width:100vw;
    height:100vh
  }
</style>
