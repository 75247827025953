<template>
  <div class="login">
    <h4>{{title}}</h4>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="form"
      label-width="100px"
      status-icon
      :validate-on-rule-change="true"
    >
      <el-form-item label="账号:" prop="name">
        <el-input  v-on:keydown.enter="submitForm" v-model="ruleForm.name" placeholder="账号"></el-input>
      </el-form-item>
      <el-form-item label="密码:" prop="pass">
        <el-input v-on:keydown.enter="submitForm"
          v-model="ruleForm.pass"
          placeholder="密码"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm" class="btn-login"
          >登录</el-button
        >
      </el-form-item>
      <el-form-item> </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, provide, watch, onUpdated, onMounted } from "vue";
import { ElMessage } from "element-plus";
import router from "../router/index.js";
import { postLogin } from "../http/login.js";
import { useStore } from "vuex";
import { saveAccount } from "../utils/localStorage";
import axios from 'axios'
export default {
  name: "Login",
  setup() {
    const store = useStore();
    const { ctx } = getCurrentInstance();
    const form = ref(null);
    const color = ref("red");
    const title=ref("丹明霞后台管理系统");
    provide("color", color);
    //双向绑定
    const ruleForm = reactive({
      pass: "",
      name: "",
    });

    //进来储存数据
    const current = reactive({
      userName: "",
      oldPwd: "",
      account: "",
    });
    onMounted(()=>{
     axios.get('https://rhdmx/building/getcompanyname')
     .then(function(response){
       console.log(response)
       document.title=response.data.data
       this.title=response.data.data+"后台管理系统"
     }).catch(function(error){

     })

    })
    //表单校验
    const rules = reactive({
      name: [{ required: "true", message: "账号必填", trigger: ["blur"] }],
      pass: [{ required: "true", message: "账密码必填", trigger: ["blur"] }],
    });
    //提交事件
    function submitForm() {
      form.value.validate((valid) => {
        if (valid) {
          postLogin(ruleForm.name, ruleForm.pass)
            .then((res) => {
              let data = res.data;
              let state = data.state;
              console.log(data);
              if (state == 0) {
                current.userName = data.data.user_name;
                current.account = data.data.account;
                current.oldPwd = ruleForm.pass;

                // 路由跳转
                router.push("/home");
                // 储存全局消息
                store.commit("submit", current);

                //浏览器存储 account
                saveAccount(current.account);

                //成功消息
                ElMessage.success({
                  message: "成功进入后台",
                  type: "success",
                  center: true,
                  duration: 1000,
                });
              } else if (state == "1") {
                ruleForm.name = "";
                ruleForm.pass = "";
                ElMessage.error(data.message);
              }
            })
            .catch((err) => {
              ElMessage.error("登录失败");
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }

    //返回数据
    return {
      form,
      ruleForm,
      submitForm,
      rules,
      current,
      color,title
    };
  },
};
</script>
<style scoped>
.login {
  width: 450px;
  margin: 250px auto;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 20px;
}
h4 {
  text-align: center;
}
.btn-login {
  width: 100%;
}
</style>
