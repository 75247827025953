import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: "/Information",
        name: 'Information',
        meta: {
          title: '房屋信息',
          isShow: true,
          allow: ['admin']
        },
        component: () => import('../views/Information.vue')
      },
      {
        path: "/pay",
        name: 'Pay',
        meta: {
          title: '缴费录入',
          isShow: true,
          allow: ['admin', 'rhdx','kefu']
        },
        component: () => import('../views/Pay.vue')
      },
      {
        path: "/docking",
        name: 'Docking',
        meta: {
          title: '服务对接',
          isShow: true,
          allow: ['admin']
        },
        component: () => import('../views/Docking.vue')
      },
      {
        path: "/notice",
        name: 'Notice',
        meta: {
          title: '通知发送',
          isShow: true,
          allow: ['admin', 'guest']
        },
        component: () => import('../views/Notice.vue')
      },
      {
        path: "/complaint",
        name: 'Complaint',
        meta: {
          title: '投诉建议',
          isShow: true,
          allow: ['admin', 'guest']
        },
        component: () => import('../views/Complaint.vue')
      },
      {
        path: "/repair",
        name: 'Repair',
        meta: {
          title: '报修服务',
          isShow: true,
          allow: ['admin', 'guest']
        },
        component: () => import('../views/Repair.vue')
      },
      {
        path: "/community",
        name: 'Community',
        meta: {
          title: '小区通知',
          isShow: true,
          allow: ['admin', 'guest']
        },
        component: () => import('../views/Community.vue')
      },
      {
        path: "/password",
        name: 'Password',
        meta: {
          title: '修改密码',
          password: true,
          allow: []
        },
        component: () => import('../views/Password.vue')
      },
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
