<template>
  <div class="home">
    <el-container style="border: 1px solid #eee">
      <el-aside width="200px" style="background-color: rgb(200, 200, 200)">
        <el-menu :default-openeds="['1']" :router="true">
          <el-submenu index="1">
            <template #title><i class="el-icon-menu"></i>物业管理</template>
            <el-menu-item
              v-for="(item, iindex) in menuItem"
              :key="iindex"
              :index="item.path"
            >
              <template #title>
                {{ item.meta.title }}
                <el-badge
                  class="mark"
                  :value="store.state.complaintNum"
                  v-if="item.meta.title == '投诉建议'"
                  :hidden="store.state.complaintNum == 0"
                ></el-badge>
                <el-badge
                  class="mark"
                  :value="store.state.repairNum"
                  v-if="item.meta.title == '报修服务'"
                  :hidden="store.state.repairNum == 0"
                ></el-badge>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template #title>
              <i class="el-icon-key"></i>
              密码管理
            </template>
            <el-menu-item
              v-for="(item, iindex) in keyItem"
              :key="iindex"
              :index="item.path"
            >
              <template #title>
                {{ item.meta.title }}
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右边栏 -->
      <el-container>
        <!-- <el-header style="text-align: right; font-size: 12px" class="header">
          <el-dropdown>
            <i class="el-icon-setting" style="margin-right: 15px"></i>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>查看</el-dropdown-item>
                <el-dropdown-item>新增</el-dropdown-item>
                <el-dropdown-item>删除</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span>管理员</span>
        </el-header> -->

        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import router from "../router/index.js";
import { getnNum } from "../http/home";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { readAccount } from "../utils/localStorage";
// @ is an alias to /src
export default {
  name: "Home",
  setup() {
    const store = useStore();
    const { ctx } = getCurrentInstance();
    const menuItem = router.options.routes[1].children.filter((item) => {
      return item.meta.allow.includes(store.state.account || readAccount());
    });
    const keyItem = router.options.routes[1].children.filter((item) => {
      return item.meta.password;
    });

    //获取投诉报修数量
    getnNum()
      .then((res) => {
        let data = res.data;
        let state = data.state;
        if (state == 0) {
          let arr = data.data;
          store.commit("complaint", arr[0]);
          store.commit("repair", arr[1]);
        }
      })
      .catch((err) => {
        ElMessage.error("获取投诉报修数量出错");
      });

    return {
      menuItem,
      keyItem,
      store,
    };
  },
};
</script>
<style scoped>
.home {
  width: 100vw;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
}
.el-container {
  height: 100%;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  background: #333;
}
.el-main {
  padding: 5px;
}
.header {
  height: 20px;
}
</style>
